import { stringMinify } from 'utils/StringUtils';
import useResize from 'components/Hooks/useResize';
import dynamic from 'next/dynamic';
import { DeviceEnum } from 'models/enums';
import Link from 'next/link';

const HtmlComponent = dynamic(() => import('components/Html/Html'));

const BannerButtons = ({ settings }: any) => {
    const { whichDevice } = useResize();

    const isMobileDevice = whichDevice === DeviceEnum.MOBILE;

    const {
        showBannerButtons,
        showSearchBar,
        bannerButtons,
        buttonsPositionDefault = 5,
        buttonsPositionTablet,
        buttonsPositionMobile,
    } = settings;

    if (!showBannerButtons) return null;

    const createButtons = (bannerButtonList: any) => {
        if (!bannerButtonList) return null;
        return bannerButtonList.map((record: any, index: number) => {
            const {
                showButton,
                showButtonMobile,
                buttonText,
                buttonLink,
                customLink,
                textColor,
                backgroundColor,
                textHoverColor,
                backgroundHoverColor,
                customClass = '',
                customId,
                useSkinColor,
                openInNewPage,
            } = record.value;

            if ((!showButton && !isMobileDevice) || (!showButtonMobile && isMobileDevice)) return null;

            let cssButtonClass = 'button';
            let cssButtonStyle = null;
            if (!useSkinColor) {
                cssButtonClass = `banner-button-${index}`;
                cssButtonStyle = `
                .${cssButtonClass} {
                    background: ${backgroundColor} !important;
                    background-color: ${backgroundColor} !important;
                    color: ${textColor} !important;
                }
                .${cssButtonClass}:hover {
                    background: ${backgroundHoverColor}  !important;
                    background-color: ${backgroundHoverColor} !important;
                    color: ${textHoverColor} !important;
                    opacity: 0.8;
                }`;
            }

            const linkButton = customLink || buttonLink;
            const allowReload = customClass.includes('no-prefetch');

            return (
                <>
                    {!allowReload && (
                        <Link prefetch={false} id={customId} className={`btn ${cssButtonClass} ${useSkinColor ? 'red' : ''} ${customClass}`} key={index} href={linkButton} target={(openInNewPage ? "_blank" : "_self")} rel="noreferrer">
                            {buttonText ? <HtmlComponent key={index} htmlValue={buttonText} /> : '&nbsp;'}
                            {cssButtonStyle && (<style jsx>{`${stringMinify(cssButtonStyle)}`}</style>)}
                        </Link>
                    )}
                    {allowReload && (
                        <a id={customId} className={`btn ${cssButtonClass} ${useSkinColor ? 'red' : ''} ${customClass}`} key={index} href={linkButton} target={(openInNewPage ? "_blank" : "_self")} rel="noreferrer">
                            {buttonText ? <HtmlComponent key={index} htmlValue={buttonText} /> : '&nbsp;'}
                            {cssButtonStyle && (<style jsx>{`${stringMinify(cssButtonStyle)}`}</style>)}
                        </a>
                    )}
                </>
            );

        });
    };

    const customStyle = `
        .video-slider section.ax-over-btn,
        .slider section.ax-over-btn {
            bottom: ${buttonsPositionDefault}% !important;
        }
        ${buttonsPositionTablet && (
        `@media screen and (max-width: 768px) {
                .video-slider section.ax-over-btn,
                .slider section.ax-over-btn {
                    bottom: ${buttonsPositionTablet}% !important;
                }
            }`
    )}
    ${buttonsPositionMobile && (
        
        `@media screen and (max-width: 448px) {
            .video-slider section.ax-over-btn,
            .slider section.ax-over-btn {
                bottom: ${buttonsPositionMobile}% !important;
            }
        }`
    )}  
    `;

    return (
        <>
            <style jsx>{`${stringMinify(customStyle)}`}</style>
            <section className="ax-over-btn">
                <div className="btn-div-center">
                    <div className="btn-group-center">
                        {createButtons(bannerButtons)}
                    </div>
                </div>
            </section>
            {showSearchBar && <span>Implement Search Bar Here</span>}
        </>
    );
};

export default BannerButtons;